import React from 'react'
import {
    AppBar,
    Layout,
    MenuItemLink,
    UserMenu,
    Logout,
    LocalesMenuButton,
    RefreshIconButton,
    ToggleThemeButton,
} from 'react-admin'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import '../App.css' //plik css
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import {ContactWidget} from './Contact/ContactWidget'

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: -10,
    },

    logo: {
        maxWidth: '60px',
    },

    bell: {
        color: '#ff0000 !important',
    },
})

const CustomUserMenu = (props) => (
    <UserMenu
        {...props}
        icon={
            <Avatar
                sx={{
                    height: 25,
                    width: 25,
                    color: '#fff',
                    backgroundColor: '#240a51',
                }}

                //src="https://marmelab.com/images/avatars/adrien.jpg"
            />
        }
    >
        <MenuItemLink to="/mydata" primaryText="Moje dane" />
        <MenuItemLink
            to="/user-notifications"
            primaryText="Powiadomenia"
            leftIcon={
                localStorage.getItem('hideBell') != 'true' ? (
                    <div style={{ padding: '0 0 10px 20px' }}>
                        <FontAwesomeIcon
                            icon={faBell}
                            color="#FF0000"
                            size="xs"
                        />
                    </div>
                ) : null
            }
            style={
                localStorage.getItem('hideBell') != 'true'
                    ? { margin: '0 0 0 -20px' }
                    : null
            }
        />
        <Logout />
    </UserMenu>
)

const CustomAppBar = (props) => {
    const classes = useStyles()

    return (
        <AppBar
            {...props}
            userMenu={<CustomUserMenu />}
            color="white"
            toolbar={
                <>
                    <ToggleThemeButton />
                    <RefreshIconButton color="primary" />
                </>
            }
        >
            <Typography color="primary.light" variant="h6">
                Witaj w panelu klienta
            </Typography>

            <img src="logo.svg" alt="logo" className={classes.logo} />
            <div className={'spacer'}></div>
            <Typography variant="h6" color="primary" id="react-admin-title" />
            <IconButton
                href={'#/user-notifications'}
                style={
                    localStorage.getItem('hideBell') == 'true'
                        ? { display: 'none' }
                        : null
                }
            >
                <FontAwesomeIcon
                    icon={faBell}
                    className={classes.bell}
                    color="#FF0000"
                    size="xs"
                />
            </IconButton>
        </AppBar>
    )
}

const MainLayout = (props) => {
    return (
        <>
            <Layout
                sx={{ marginTop: '10px' }}
                {...props}
                appBar={CustomAppBar}
            />
            <ContactWidget />
        </>
    )
}

export default MainLayout
