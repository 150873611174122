import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslate, useSafeSetState } from 'ra-core'
import { useSnackbar } from 'notistack'
import {
    useLogin,
    required,
    TextInput,
    PasswordInput,
    Form,
    useDataProvider,
} from 'react-admin'
import { useState } from 'react'
import { Avatar, Card, CardActions } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles, withStyles } from '@mui/styles'
import { useNavigate, re } from 'react-router-dom'
import authProvider from '../../config/authProvider'

// import '../../login-styles.css';

const useStyles = makeStyles((theme) => ({
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}))

const styles = {
    login: {
        main: {
            background: 'none',
            height: 'auto',
            minHeight: 'inherit',
        },
        card: {
            boxShadow: 'none',
            marginTop: '0px',
            minWidth: '0px',
            width: '400px',
        },
        avatar: {
            display: 'none',
        },
    },
}

const Login = (props) => {
    const navigate = useNavigate()
    const dataProvider = useDataProvider()
    const [loading, setLoading] = useSafeSetState(false)
    const login = useLogin()
    const translate = useTranslate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const validate = (values) => {
        const errors = { username: undefined, password: undefined }

        if (!values.username) {
            errors.username = translate('ra.validation.required')
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required')
        }
        return errors
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const submit = () => {
        const values = {username: username.trim(), password: password}
        setLoading(true)
        login(values)
            .then(() => {
                setLoading(false)
                dataProvider
                    .getOne('pk_users', { id: `pk_users/me` })
                    .then((resp) => {
                        let data = resp?.data
                        localStorage.setItem('partnerId', data?.partnerId)
                        localStorage.setItem('shopUrl', data?.shopUrl)
                        localStorage.setItem('partnerName', data?.partnerName)
                        localStorage.setItem('shopId', data?.shopId);
                        localStorage.setItem('indOrds', data?.isIndividualOrderAvailable);

                        enqueueSnackbar(
                            `Witaj ${data?.firstName}. Twoje logowanie przebiegło poprawnie`,
                            {
                                variant: 'success',
                                autoHideDuration: 10000,
                            }
                        )

                        if (data?.isBlocked == true) {
                            enqueueSnackbar(
                                <>
                                    Uwaga, posiadasz zaległe płatności. Obecnie
                                    składanie zamówień nie jest możliwe.
                                    <br />
                                    Ureguluj zaznaczone dokumenty lub skontaktuj
                                    się z przedstawicielem TZMO
                                </>,
                                {
                                    variant: 'error',
                                    autoHideDuration: 10000,
                                }
                            )
                        }

                        authProvider.getNotifications().then((data) => {
                            data?.map((x) => {
                                if (
                                    x.level != 'success' &&
                                    x.level != 'danger'
                                ) {
                                    return
                                }
                                enqueueSnackbar(x.message, {
                                    variant:
                                        x.level == 'danger' ? 'error' : x.level,
                                    autoHideDuration: 10000,
                                    preventDuplicate: true,
                                })
                            })
                        })
                    })

                    .finally(() => {})
            })
            .catch((error) => {
                setLoading(false)
                enqueueSnackbar('Niepoprawna nazwa użytkownika lub hasło', {
                    variant: 'error',
                    autoHideDuration: 10000,
                })
            })
    }

    return (
        <>
            <link rel="stylesheet" href="/login-styles.css" />
            <div class="login-wrapper wrapper">
                <div class="container">
                    <div class="form">
                        <div>
                            <img
                                src="/img/login/logo.svg"
                                alt="TZMO Online"
                                class="logo"
                            />
                        </div>
                        <p class="color">
                            Platforma zakupowa produktów{' '}
                            <span style={{ color: 'rgb(225, 138, 61)' }}>
                                Grupy
                            </span>{' '}
                            TZMO
                        </p>

                        <Form
                            defaultValues={{
                                username: '',
                                password: '',
                            }}
                        >
                            <div className="form-login">
                                <div className="f-group">
                                    <TextInput
                                        autoFocus
                                        source="username"
                                        disabled={loading}
                                        value={username}
                                        validate={required()}
                                        fullWidth={true}
                                        variant="outlined"
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                        label="Login - adres e-mail"
                                    />
                                </div>
                                <div className="f-group">
                                    <PasswordInput
                                        source="password"
                                        label={translate('ra.auth.password')}
                                        value={password}
                                        disabled={loading}
                                        validate={required()}
                                        fullWidth={true}
                                        variant="outlined"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="f-group">
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disableElevation
                                        disabled={loading}
                                        className="primary f-control f-submit"
                                        onClick={submit}
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                                sx={{
                                                    svg: { color: '#240a51' },
                                                    marginRight: 2,
                                                }}
                                            />
                                        )}
                                        {translate('ra.auth.sign_in')}
                                    </Button>
                                </div>
                                <p>
                                    Jeśli nie pamiętasz hasła, skorzystaj z
                                    funkcji resetowania hasła dostępnej{' '}
                                    <a href="#/reset_password" class="orn">
                                        tutaj
                                    </a>
                                </p>
                                <div class="logos">
                                    <img src="/img/login/tzmo-min.png" />
                                    <img src="/img/login/smp-min.png" />
                                    <img src="/img/login/cit-min.png" />
                                </div>
                            </div>
                        </Form>
                    </div>

                    <p>
                        Czym jest nasza{' '}
                        <strong>
                            <span style={{ color: 'rgb(225, 138, 61)' }}>
                                platforma zakupowa?
                            </span>
                        </strong>
                    </p>

                    <div class="bar">
                        <div class="bar-content">
                            <div class="bar-item">
                                <img src="/img/login/cart.svg" />
                                <span>
                                    platformą do składania zamówień online
                                </span>
                            </div>
                            <div class="bar-item">
                                <img src="/img/login/pig.svg" />
                                <span>
                                    miejscem bardzo dobrych warunków handlowych
                                </span>
                            </div>
                            <div class="bar-item">
                                <img src="/img/login/hed.svg" />
                                <span>
                                    miejscem komunikacji z aptekami i sklepami
                                    medycznymi
                                </span>
                            </div>
                            <div class="bar-item">
                                <img src="/img/login/serwer.svg" />
                                <span>
                                    agregatorem danych o: zamówieniach,
                                    płatnościach i zobowiązaniach
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="foot">
                        <p>
                            Jeśli masz problem z logowaniem, skontaktuj się z
                            nami:{' '}
                            <a href="tel:56 612 38 00">56 612 38 00 wew.1</a>,{' '}
                            <a href="mailtto:bok@tzmo-global.com">
                                bok@tzmo-global.com
                            </a>{' '}
                            pracujemy w godzinach 8-16 pn-pt
                            <br/>
                            <a href='https://tzmo-global.com/pl_PL/location/torunskie-zaklady-materialow-opatrunkowych-sa' target='_blank'>STRONA WWW</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
}

export default withStyles(styles.login)(Login)
