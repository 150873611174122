import * as React from 'react'
import {
    Edit,
    required,
    SimpleForm,
    TextInput,
    usePermissions,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
} from 'react-admin'

import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'
import { Divider, Typography } from '@mui/material'
import Toolbar from './partials/ProductSearch'
import ProductList from './partials/ProductList'

const requiredField = required('Pole wymagane')
const requiredIdentity = (value, allValues) => {
    if (!allValues.pesel && !allValues.otherIdentity) {
        return 'Brak numeru dokumentu'
    }

    return null
}

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    return (
        <Edit {...props} actions={null} title={`Dodaj pacjenta`}>
            <SimpleForm toolbar={<PostBottomToolbarEdit showRedirectPath />}>
                <div className="d-flex flex-column w-100 justify-content-around">
                    <div className="col-sm-5 align-self-center">
                        <TextInput
                            variant="outlined"
                            label={'Imię'}
                            source="name"
                            validate={requiredField}
                            fullWidth
                        />
                        <TextInput
                            variant="outlined"
                            label={'Drugie imię'}
                            source="secondName"
                            fullWidth
                        />
                        <TextInput
                            variant="outlined"
                            label={'Nazwisko'}
                            source="surname"
                            validate={requiredField}
                            fullWidth
                        />
                        <TextInput
                            variant="outlined"
                            source="pesel"
                            label="Numer Pesel"
                            fullWidth
                            validate={requiredIdentity}
                        />
                        <TextInput
                            variant="outlined"
                            source="otherIdentity"
                            label="Inny dokument"
                            fullWidth
                            validate={requiredIdentity}
                        />
                        <Divider sx={{ margin: '10px 0px 20px 0' }} />
                        <Typography variant="subtitle1" gutterBottom>
                            Dane dodatkowe jeśli adres inny niż placówki
                        </Typography>
                        <TextInput
                            variant="outlined"
                            label={'Ulica'}
                            source="street"
                            fullWidth
                        />
                        <TextInput
                            variant="outlined"
                            label={'Numer'}
                            source="number"
                            fullWidth
                        />
                        <TextInput
                            variant="outlined"
                            label={'Kod pocztowy'}
                            source="postCode"
                            fullWidth
                        />
                        <TextInput
                            variant="outlined"
                            label={'Miejscowość'}
                            source="city"
                            fullWidth
                        />
                        <Divider sx={{ margin: '10px 0px 20px 0' }} />
                        <ReferenceInput
                            source="department"
                            reference="departments"
                        >
                            <AutocompleteInput
                                optionText="name"
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                                label={'Oddział'}
                                fullWidth
                                variant="outlined"
                                validate={requiredField}
                                resettable
                            />
                        </ReferenceInput>
                        <TextInput
                            variant="outlined"
                            label={'Numer pokoju'}
                            source="room"
                            validate={requiredField}
                            fullWidth
                        />
                        <BooleanInput source="status" label="Status" />
                        <Divider sx={{ margin: '10px 0px 20px 0' }} />
                    </div>
                    <Typography variant="subtitle1" gutterBottom>
                        Przypisane produkty
                    </Typography>
                    <ProductList />
                    <Typography variant="subtitle1" gutterBottom>
                        Przypisz produkty
                    </Typography>
                    <Toolbar />
                </div>
            </SimpleForm>
        </Edit>
    )
}
