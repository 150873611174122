import * as React from 'react'
import useStateRef from 'react-usestateref'
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
    IconButton,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Title, useGetList, useStore, useDataProvider, useRedirect } from 'react-admin'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
import OrderTypeSelection from './partials/OrderTypeSelection'
import ProductSearch from './partials/ProductSearch'
import OrderLines from './partials/OrderLines'
import RefundScale from '../Patients/partials/RefundScale'
import NewTemplateModal from './partials/NewTemplateModal'
import dpsProvider from '../../config/dpsProvider'
import fastOrderProvider from '../../config/fastOrderProvider'

const refundRangeOptions = [
    { value: 1, label: '1 miesiąc' },
    { value: 2, label: '2 miesiące' },
    { value: 3, label: '3 miesiące' },
]

const getTitle = (orderProps) => {
    let result = ''
    switch (orderProps.orderType) {
        case 1:
            result += 'Zamówienie z dofinansowaniem NFZ, '
            break
        case 2:
            result += 'Zamówienie bez dofinansowania NFZ, '
            break
    }

    switch (orderProps.invoice) {
        case 'patient':
            result += 'faktura na podopiecznego'
            break
        case 'dps':
            result += 'faktura na DPS'
            break
    }

    return result
}

const checkoutStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const refundStatus = [
    { '2023-10': 'inactive' },
    { '2023-11': 'inactive' },
    { '2023-12': 'inactive' },
    { '2024-01': 'inactive' },
    { '2024-02': 'inactive' },
    { '2024-03': 'inactive' },
    { '2024-04': 'active' },
    { '2024-05': 'active' },
    { '2024-06': 'active' },
    { '2024-07': 'active' },
    { '2024-08': 'active' },
    { '2024-09': 'active' },
    { '2024-10': 'unavailable' },
]

const currencyFormatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
})

export default () => {
    const dataProvider = useDataProvider()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const confirm = useConfirm()
    const redirect = useRedirect()

    const [patients, setPatients] = React.useState([])
    const [dpsPreferences, setDpsPreferences] = useStore('order_dps_preferences', false)
    const [refundRange, setRefundRange] = React.useState(1)
    const [expandAll, setExpandAll] = React.useState(false)
    const [shouldSelectAll, setShouldSelectAll] = React.useState(true)
    const [expanded, setExpanded, expandedRef] = useStateRef({})
    const [modalOpen, setModalOpen] = React.useState(false)
    const [sending, setSending, sendingRef] = useStateRef(false)

    const [dpsDetails, setDpsDetails] = useStore('order_dps_details', [])
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    React.useEffect(() => {
        const deptFilter = dpsPreferences?.department
            ? Object.entries(dpsPreferences?.department)
                  .filter((key, val) => !val)
                  .map((x) => x[0].split('/')[2])
            : null
        if (!deptFilter?.length) {
            setPatients([])
            return
        }
        dataProvider
            .getList('patients', {
                pagination: { page: 1, perPage: 999 },
                sort: { field: 'id', order: 'DESC' },
                filter: { department: deptFilter },
            })
            .then((result) => {
                setPatients(result.data)
                result?.data?.map((patient) => {
                    fastOrderProvider.getPatientProducts(patient._id).then((products) => {
                        const existsPatient = dpsDetails.findIndex((obj) => obj.patient_id == patient._id);
                        const haveProducts = dpsDetails?.[existsPatient]?.products?.length > 0;
                        products?.map((product) => {
                           if (!haveProducts) {
                                dpsDetails.push({ patient_id: patient._id, products: [product] })
                                setDpsDetails([...dpsDetails])
                            }
                        })
                    })
                })
            })
    }, [dpsPreferences])

    const selectAll = () => {
        setDpsDetails(dpsDetails.map((d) => ({ ...d, selected: shouldSelectAll })))
        setShouldSelectAll(!shouldSelectAll)
        forceUpdate()
    }

    const PatientSummary = ({ patient, active = false, dpsDetails }) => {
        const orderDetails = dpsDetails?.find((obj) => obj.patient_id == patient._id)
        const subTotalSummary = orderDetails?.products?.reduce(
            (_accumulator, _currentValue) => _accumulator + _currentValue.price * _currentValue.quantity,
            0
        )
        //TODO temporary mock value
        const subPatientSummary = subTotalSummary / 10

        return !active ? (
            <Box sx={{ display: 'flex', flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1, minWidth: 400, marginRight: 5 }}>
                    <Typography>{`${patient.name} ${patient.secondName ? `${patient.secondName} ${patient.surname}` : patient.surname} (ID ${
                        patient._id
                    })`}</Typography>
                    <Typography>{patient?.refundDocument?.spanLimit * refundRange} szt</Typography>
                </Box>

                <Box sx={{ display: 'flex', flex: 2, justifyContent: 'space-around' }}>
                    {orderDetails?.products && (
                        <>
                            <Divider orientation="vertical" />
                            <Box>
                                {orderDetails?.products.map((p) => (
                                    <Typography>{p.name}</Typography>
                                ))}
                            </Box>
                            <Box>
                                <Typography>wartość</Typography>
                                <Typography fontWeight="bold">{currencyFormatter.format(subTotalSummary)}</Typography>
                            </Box>
                            <Box>
                                <Typography>dopłata pacjenta</Typography>
                                <Typography fontWeight="bold">{currencyFormatter.format(subPatientSummary)}</Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        ) : (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                <Typography>{`${patient.name} ${patient.secondName ? `${patient.secondName} ${patient.surname}` : patient.surname} (ID ${
                    patient._id
                })`}</Typography>
                <Box sx={{ display: 'flex' }}>
                    <Typography marginRight={5}>Oddział:</Typography>
                    <Typography fontWeight="bold">{patient._department.name}</Typography>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography marginRight={5}>Miesięczna ilość:</Typography>
                        <Typography fontWeight="bold">{patient?.refundDocument.spanLimit}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography marginRight={5}>Dostępna ilość:</Typography>
                        <Typography fontWeight="bold">{patient?.refundDocument.availableLimit}</Typography>
                    </Box>
                </Box>
                <Box>
                    <RefundScale refundMonths={refundStatus} />
                </Box>
            </Box>
        )
    }

    const handleSelect = (patientId) => {
        const dpsDetailsIndex = getPatientIndex(patientId)
        if (dpsDetails[dpsDetailsIndex]) {
            dpsDetails[dpsDetailsIndex].selected = !dpsDetails[dpsDetailsIndex].selected
            setDpsDetails(dpsDetails)
            forceUpdate()
        }
    }

    const handleChange = (patientId) => (event) => {
        expandedRef.current[patientId] = !expandedRef.current[patientId]
        setExpanded(expandedRef.current)
        forceUpdate()
    }

    const getPatientIndex = (patientId) => dpsDetails.findIndex((obj) => obj.patient_id == patientId)

    const canSubmitOrder = (onlySelected = false) => {
        return (
            dpsDetails.filter((patient) => (onlySelected ? patient?.selected && patient?.products?.length > 0 : patient?.products?.length > 0))
                .length > 0
        )
    }

    const submitOrder = () => {
        setSending(true)
        if (!canSubmitOrder(true)) {
            enqueueSnackbar('Brak zaznaczonych pacjentów lub brak produktów', {
                variant: 'error',
                autoHideDuration: 5000,
            })
            return
        }
        const orderLines = dpsDetails
            .filter((oL) => oL?.selected)
            .map((_oL) => ({
                patientId: _oL.patient_id,
                products: _oL?.products?.map(({ description, categories, attributes, features, ...other }) => other),
            }))
        const formData = new FormData()
        formData.append('orderPreferences', JSON.stringify(dpsPreferences))
        formData.append('orderLines', JSON.stringify(orderLines))

        dpsProvider
            .submitOrder(formData)
            .then((resp) => {
                confirm({
                    title: 'Zamówienie zostało pomyślnie złożono',
                    description: `Twoje zamówienie zostało złożone i niedługo przesłane do realizacji.`,
                    confirmationText: 'Przejdź na stronę główną',
                    confirmationButtonProps: {
                        variant: 'contained',
                        color: 'success',
                    },
                    allowClose: false,
                    hideCancelButton: true,
                }).then(() => {
                    redirect('/')
                })
            })
            .catch((resp) => {
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => {
                setSending(false)
            })
    }

    const Summary = () => {
        const _summaryTotal = dpsDetails?.reduce(
            (accumulator, currentValue) =>
                accumulator +
                (currentValue?.selected
                    ? currentValue?.products?.reduce((_accumulator, _currentValue) => _accumulator + _currentValue.price * _currentValue.quantity, 0)
                    : 0),
            0
        )

        //TODO temporary mock value
        const _summaryPacient = _summaryTotal / 10
        const _summaryNfz = _summaryTotal / 1.4
        const _summaryDps = _summaryTotal / 4.1

        return (
            <>
                <Box sx={checkoutStyles}>
                    <Typography>Wartość zamówienia</Typography>
                    <Typography>{currencyFormatter.format(_summaryTotal)}</Typography>
                </Box>
                {dpsPreferences.orderType == 1 && (
                    <>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty Pacjentów</Typography>
                            <Typography>{currencyFormatter.format(_summaryPacient)}</Typography>
                        </Box>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty NFZ</Typography>
                            <Typography>{currencyFormatter.format(_summaryNfz)}</Typography>
                        </Box>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Box sx={checkoutStyles}>
                            <Typography>Wartość dopłaty DPS</Typography>
                            <Typography>{currencyFormatter.format(_summaryDps)}</Typography>
                        </Box>
                    </>
                )}
            </>
        )
    }

    return (
        <Box>
            <Card>
                <Title title="Zamówienie DPS" />
                <CardContent>
                    {!dpsPreferences ? (
                        <OrderTypeSelection />
                    ) : (
                        <Box>
                            <NewTemplateModal isOpen={modalOpen} setIsOpen={setModalOpen} products={{ ...dpsPreferences, patients: dpsDetails }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="warning"
                                    onClick={() => {
                                        setDpsPreferences(false)
                                        setDpsDetails([])
                                    }}
                                >
                                    Wróć do ustawień
                                </Button>
                                <Typography variant="subtitle1" maxWidth={400}>
                                    {dpsPreferences && getTitle(dpsPreferences)}
                                </Typography>
                                <TextField
                                    select
                                    variant="outlined"
                                    sx={{ minWidth: 200 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Okres:</InputAdornment>,
                                    }}
                                    value={refundRange}
                                    onChange={(e) => setRefundRange(e.target.value)}
                                >
                                    {refundRangeOptions.map((o) => (
                                        <MenuItem value={o.value}>{o.label}</MenuItem>
                                    ))}
                                </TextField>
                                <Box sx={{ display: 'flex' }}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        label="Rozwiń/zwiń wszystkie"
                                        control={<Switch onClick={() => setExpandAll(!expandAll)} />}
                                    />
                                    <FormControlLabel
                                        labelPlacement="start"
                                        label="Zaznacz/odznacz wszystkie"
                                        control={<Checkbox onClick={() => selectAll()} />}
                                    />
                                </Box>
                            </Box>
                            {patients && (
                                <Box sx={{ marginTop: 3 }}>
                                    {patients?.map((patient) => (
                                        <Box sx={{ display: 'flex' }}>
                                            <Accordion
                                                key={patient.id}
                                                expanded={expandedRef.current[patient.id] || expandAll}
                                                onChange={handleChange(patient.id)}
                                                sx={{ flex: 1 }}
                                            >
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <PatientSummary
                                                        patient={patient}
                                                        active={expandedRef.current[patient.id] || expandAll}
                                                        dpsDetails={dpsDetails}
                                                    />
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box>
                                                        <OrderLines patient={patient} />
                                                    </Box>
                                                </AccordionDetails>
                                                <AccordionActions>
                                                    <ProductSearch patient={patient} forceUpdate={forceUpdate} />
                                                </AccordionActions>
                                            </Accordion>
                                            <Checkbox
                                                sx={{ alignSelf: 'baseline' }}
                                                disabled={!dpsDetails[getPatientIndex(patient._id)]}
                                                checked={dpsDetails[getPatientIndex(patient._id)]?.selected}
                                                onChange={() => handleSelect(patient._id)}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', margin: '30px 0' }}>
                                <Box sx={{ flex: 1 }} />
                                <Box sx={{ flex: 1 }}>
                                    <Divider sx={{ margin: '10px 0' }} />
                                    <Summary />
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                                        <Button
                                            disabled={!canSubmitOrder(false) || sendingRef.current}
                                            onClick={() => setModalOpen(true)}
                                            sx={{ marginRight: '20px' }}
                                            variant="contained"
                                            color="warning"
                                            startIcon={<DynamicFormIcon />}
                                        >
                                            Zapisz jako szablon
                                        </Button>
                                        <Button
                                            disabled={!canSubmitOrder(true) || sendingRef.current}
                                            onClick={() => submitOrder()}
                                            variant="contained"
                                            color="success"
                                        >
                                            Przejdź do realizacji zamówienia
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}
