import decodeJwt from 'jwt-decode'
import md5 from 'md5'

function createHeader() {
    const token = localStorage.getItem('token')
    const headers = new Headers()

    headers.append('Authorization', 'Bearer ' + token)

    return headers
}

async function uploadOrderFile(formData) {
    const request = new Request(
        `${process.env.REACT_APP_API_ENTRYPOINT}/import-invoice`,
        {
            method: 'POST',
            headers: createHeader(),
            body: formData,
        }
    )
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((e) => {
                throw e?.['hydra:description']
            })
        } else return response.json()
    })
}

async function searchProducts(
    bloz7 = null,
    ean = null,
    productName = null,
    isDps = false,
    category = null,
    size = null,
    shopId = 1,
    institutionId = null
) {
    const request = new Request(
        `${process.env.REACT_APP_API_ENTRYPOINT}/search-products?filter[bloz]=${
            bloz7 ?? ''
        }&filter[ean]=${ean ?? ''}&filter[name]=${
            productName ?? ''
        }&filter[category]=${category ?? ''}&filter[size]=${
            size ?? ''
        }&filter[shopId]=${shopId ?? ''}&filter[isDps]=${
            isDps ?? ''
        }&filter[institutionId]=${institutionId ?? ''}`,
        {
            method: 'GET',
            headers: createHeader(),
        }
    )
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.text()
        } else return response.json()
    })
}

function redirectToPrestashopOrder(mappedProducts) {
    const token = localStorage.getItem('token')
    const decodedToken = decodeJwt(token)
    const username = decodedToken.username
    const hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH)
    const prestaUrl = localStorage.getItem('shopUrl')

    const url =
        prestaUrl +
        '/superlogin.php?hash=' +
        hashMd5 +
        '&email=' +
        username +
        '&token=' +
        token +
        '&timestamp=' +
        Date.now() +
        '&redirect=true&product_sku=' +
        mappedProducts

    window.open(url, '_blank').focus()
}

function getPatientProducts($id_patient) {
    const request = new Request(
        `${process.env.REACT_APP_API_ENTRYPOINT}/get-patient-products?id_patient=${$id_patient}`,
        {
            method: 'GET',
            headers: createHeader(),
        }
    )
    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.text()
        } else return response.json()
    })
}

export default {
    uploadOrderFile,
    searchProducts,
    getPatientProducts,
    redirectToPrestashopOrder,
}
