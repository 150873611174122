import * as React from 'react'
import {
    DeleteButton,
    ListButton,
    SaveButton,
    Toolbar,
    TopToolbar,
    useResourceContext,
    useEditContext,
    useGetList,
    useGetOne,
    useRedirect,
    useNotify,
    usePermissions
} from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'

export const PostBottomToolbarEdit = (props) => {
    const resource = useResourceContext()
    const {permissions} = usePermissions()
    const {data:managers, isLoading:workersLoadning} = useGetList('workers',{
        filter: {type:20},
        pagination: { page:1 , perPage: 10 },
    })
    const { data:meData, isLoading:meLoadning, error } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })
    
    const { record, isLoading } = useEditContext()

    return (
        <Toolbar
            {...props}
            style={{ display: 'flex', justifyContent: 'space-between' }}
        >
            <RedirectSaveButton saveRedirectPath={props.saveRedirectPath} />
            <ListButton
                label="Anuluj"
                color="warning"
                variant="contained"
                size="medium"
                icon={<FontAwesomeIcon icon={faUndo} />}
                to={
                    props.cancelRedirectPath
                        ? props.cancelRedirectPath
                        : props.showRedirectPath
                        ? `/${resource}/${encodeURIComponent(record?.id)}/show`
                        : `/${resource}`
                }
            />
            {(props.isDeleteAvailable == undefined ||
                props.isDeleteAvailable == true) && (
                <DeleteButton
                    variant="outlined"
                    color="error"
                    size="medium"
                    disabled={resource =='workers'&& !workersLoadning && !meLoadning && (record.email == meData.email || (managers.length <= 1 ? !permissions.includes('ROLE_PAYER') || !permissions.includes('ROLE_PAYER_NETWORK') : false))}
                    redirect={`/${resource}`}
                />
            )}
        </Toolbar>
    )
}

export const PostBottomToolbarAdd = (props) => {
    const resource = useResourceContext()

    const appendId =
        typeof props?.appendId == 'function'
            ? props?.appendId()
            : props?.appendId

    return (
        <Toolbar
            {...props}
            style={{ display: 'flex', justifyContent: 'space-between' }}
        >
            <RedirectSaveButton
                saveRedirectPath={props.saveRedirectPath}
                appendId={appendId}
            />
            <ListButton
                label="Anuluj"
                color="warning"
                variant="contained"
                size="medium"
                icon={<FontAwesomeIcon icon={faUndo} />}
                to={
                    props.saveRedirectPath
                        ? props.saveRedirectPath
                        : `/${resource}`
                }
            />
        </Toolbar>
    )
}

const RedirectSaveButton = ({
    saveRedirectPath = null,
    mutationMode = 'undoable',
    appendId = false,
}) => {
    const redirect = useRedirect()
    const resource = useResourceContext()
    const notify = useNotify()

    return (
        <SaveButton
            type="button"
            variant="contained"
            color="success"
            mutationOptions={{
                onSuccess: (props) => {
                    notify('ra.notification.created', {
                        messageArgs: { smart_count: 1 },
                        undoable: mutationMode === 'undoable',
                    })
                    const url = saveRedirectPath
                        ? saveRedirectPath
                        : `/${resource}`
                    redirect(
                        appendId
                            ? url + `?id=${props.originId ?? props.id}`
                            : url
                    )
                },
            }}
        />
    )
}

export const BackToListBtn = ({ basePath }) => {
    return (
        <ListButton
            label="Wstecz"
            className="btn-cancel"
            basePath={basePath}
            icon={<FontAwesomeIcon icon={faUndo} />}
        />
    )
}

export const PostShowActions = (props) => {
    const resource = useResourceContext()
    return (
        <TopToolbar {...props}>
            <ListButton
                label="Anuluj"
                color="warning"
                variant="contained"
                size="medium"
                basePath={`/${resource}`}
            />
        </TopToolbar>
    )
}
