import * as React from 'react'
import {
    Edit,
    required,
    SimpleForm,
    usePermissions,
    SelectInput,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { PostBottomToolbarEdit } from '../../CustomElements/PostBottomToolbars'
import { CustomTitle } from '../../CustomElements/CustomTitle'

const requiredField = required('Pole wymagane')

const transform = (data) => ({
    ...data,
})

export const rewardTypes = [
    { id: 0, name: 'Brak' },
    { id: 1, name: 'Oddział' },
    { id: 2, name: 'Instytucja' },
]

export default (props) => {
    const { payerId } = useParams()
    const { isLoading, permissions } = usePermissions()

    return (
        <Edit
            resource="payers"
            id={payerId}
            actions={null}
            title={<CustomTitle resourceName="Instytucja" fields={['name']} />}
            transform={transform}
        >
            <SimpleForm
                toolbar={<PostBottomToolbarEdit isDeleteAvailable={false} />}
            >
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <SelectInput
                            variant="outlined"
                            source="rewardType"
                            label="Gromadzenie pkt."
                            choices={rewardTypes}
                            fullWidth
                        />
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
