import * as React from 'react'
import { List, TextField, FunctionField, ReferenceField, TextInput, SelectInput, ReferenceInput, AutocompleteInput,usePermissions } from 'react-admin'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { CustomPagination } from '../CustomElements/CustomPagination'
import EmptyList from '../EmptyList'
import CustomActionBar from '../CustomElements/CustomActionBar'
import RefundScale from './partials/RefundScale'
import PermissionHandler from '../../config/PermissionHandler'

const status = [
    { id: 0, name: 'aktywny' },
    { id: 1, name: 'nieaktywny' },
]

const refundStatus = [
    { '2023-10': 'inactive' },
    { '2023-11': 'inactive' },
    { '2023-12': 'inactive' },
    { '2024-01': 'inactive' },
    { '2024-02': 'inactive' },
    { '2024-03': 'inactive' },
    { '2024-04': 'active' },
    { '2024-05': 'active' },
    { '2024-06': 'active' },
    { '2024-07': 'active' },
    { '2024-08': 'active' },
    { '2024-09': 'active' },
    { '2024-10': 'unavailable' },
]

export default () => {
    const { isLoading, permissions } = usePermissions();
    return (
        <List
            title="Pacjenci"
            pagination={<CustomPagination />}
            empty={<EmptyList title="pacjentów" add={PermissionHandler(permissions, 'Patients', 'Create')} />}
            actions={<CustomActionBar isAddVisible={PermissionHandler(permissions, 'Patients', 'Create')} />}
            filters={[
                <TextInput variant="outlined" resettable label="Imię lub nazwisko" source="fullName" alwaysOn />,
                <ReferenceInput source="department" reference="departments" link={false} alwaysOn>
                    <AutocompleteInput
                        label="Oddział"
                        optionText="name"
                        variant="outlined"
                        filterToQuery={(searchText) => ({
                            name: searchText,
                        })}
                        sx={{ minWidth: 300 }}
                        resettable
                        validate={null}
                    />
                </ReferenceInput>,
                <SelectInput
                    label="Status dofinansowania"
                    source="refundStatus"
                    choices={status}
                    alwaysOn
                    variant="outlined"
                    sx={{ minWidth: 200 }}
                    resettable
                />,
            ]}
        >
            <CustomDataGrid rowClick="show">
                <FunctionField
                    render={(record) => `${record.name} ${record.secondName ?? ''} ${record.surname} (ID ${record._id})`}
                    label="Imię i Nazwisko (ID)"
                />
                <FunctionField render={(record) => <RefundScale refundMonths={refundStatus} />} label="Dofinansowanie NFZ" />
                <TextField source="_department.name" label="Oddział" />
            </CustomDataGrid>
        </List>
    )
}
