import React from 'react'

import Card from '@mui/material/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faFilePdf } from '@fortawesome/free-solid-svg-icons'
const menuElementContainer = {
    display: 'flex',
    width: '90%',
    minWidth: 100,
    minHeight: 100,
    maxHeight: 100,
    margin: 10,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}
const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-beetween',
    padding: '10px 10px',
    margin: '10px 10px',
    width: '30em',
}

const fontsStyle = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}
const fontStyle = {
    textTransform: 'normal',
    fontSize: '1rem',
}
const textBox = {
    padding: '2%',
    margin: '0%',
}

function PriceRefundation() {
    return (
        <Card>
            <div style={textBox}>
                <p>Szanowni Państwo,</p>
                <p>
                    zapraszamy do zapoznania się z <strong>cennikiem</strong> (plik PDF) dla produktów chłonnych marki Seni współfinansowanych ze
                    środków publicznych, obowiązującym <strong>OD 1 MARCA 2024 r</strong>.
                </p>
                <p>W Cenniku wprowadzono następujące zmiany:</p>
                <ul>
                    <li>
                        Usunięte zostały produkty linii <u>Seni Lady</u> w kategorii „pieluchy anatomiczne”. Od 1 marca 2024 r. produkty Seni Lady
                        będą występować <u>wyłącznie w kategorii „wkładki urologiczne”</u>;
                    </li>

                    <li>
                        <u>Usunięte zostały pieluchy anatomiczne San Seni</u> we wszystkich rozmiarach.
                        <br />
                        Pozostanie wyłącznie nowsza wersja produktów tej linii, czyli Seni San;
                    </li>

                    <li>
                        Produkty linii <u>Seni Man</u> (Extra Level 3, Extra Plus Level 4, Super Level 5) zostały dodane do cennika{' '}
                        <u>w nowej kategorii "wkładki urologiczne"</u>.<br />
                        Produkty Seni Man występowały dotychczas jako „pieluchy anatomiczne” - pozostaną w tej kategorii w cenniku do wyczerpania
                        zapasów.
                    </li>
                </ul>
                <p>Uwaga! Ceny produktów pozostały bez zmian.</p>
            </div>
            <div>
                <div class="row">
                    <div style={boxStyle}>
                        <a href={'/files/CENNIK_03.2024.pdf'} target="_blank" style={menuElementContainer} class="dash-card " download>
                            <FontAwesomeIcon icon={faFilePdf} size="lg" /> <span style={fontsStyle}>CENNIK SENI MARZEC 2024 PDF</span>
                        </a>
                    </div>
                </div>
            </div>
            <div style={textBox}>
                <p style={fontStyle}>
                    Udostępniamy Państwu również <strong>pliki CSV</strong> - wykaz wyrobów chłonnych marki Seni wraz z kodem środka pomocniczego,
                    który ułatwi kontraktację umowy z OW NFZ z zakresu zaopatrzenia w środki pomocnicze. Zamieszczony wykaz zawiera pełne nazwy
                    handlowe produktów, zgodne z dokumentami rejestracyjnymi. Wszystkie zawarte produkty spełniają wymogi aktualnie obowiązującego
                    Rozporządzenia MZ pod względem chłonności, aby móc je realizować w ramach zlecenia na zaopatrzenie w wyroby medyczne z
                    dofinansowaniem NFZ. Udostępnione pliki dedykowane są osobno <u>na SZOI</u> oraz <u>na Portal Świadczeniodawcy</u>.
                </p>
            </div>
            <div class="row">
                <div style={boxStyle}>
                    <a
                        href={'/files/CSV_MARZEC_2024_DLA_APT_I_SM_SZOI.csv'}
                        target="_blank"
                        style={menuElementContainer}
                        class="dash-card "
                        download="CSV_MARZEC_2024_DLA_APT_I_SM_SZOI.csv"
                    >
                        <FontAwesomeIcon icon={faFileCsv} size="lg" /> <span style={fontsStyle}>CSV MARZEC 2024 DLA APTEK I SM SZOI</span>
                    </a>
                </div>
                <div style={boxStyle}>
                    <a
                        href={'/files/CSV_MARZEC_2024_DLA_APT_I_SM_PORTAL.csv'}
                        target="_blank"
                        style={menuElementContainer}
                        class="dash-card "
                        download="CSV_MARZEC_2024_DLA_APT_I_SM_PORTAL.csv"
                    >
                        <FontAwesomeIcon icon={faFileCsv} size="lg" /> <span style={fontsStyle}>CSV MARZEC 2024 DLA APTEK I SM PORTAL</span>
                    </a>
                </div>
            </div>
        </Card>
    )
}
export default PriceRefundation
