import * as React from 'react'
import {
    email,
    ReferenceManyField,
    required,
    Show,
    SimpleShowLayout,
    TextField,
    usePermissions,
    BooleanField,
} from 'react-admin'

import { PostShowActions } from '../CustomElements/PostBottomToolbars'
import CustomTypeField from '../CustomFields/CustomTypeField'
import { CustomTitle } from '../CustomElements/CustomTitle'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import PermissionHandler from '../../config/PermissionHandler'

const requiredField = required('Pole wymagane')
const isEmail = [
    required('Pole wymagane'),
    email('Podany jest nie prawidłowy e-mail'),
]

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    return (
        <>
            <Show
                {...props}
                actions={<PostShowActions />}
                title={
                    <CustomTitle
                        resourceName="Punkt odbioru"
                        fields={['name']}
                    />
                }
            >
                <SimpleShowLayout>
                    <TextField source="name" label="Nazwa" />
                    <TextField source="postCode" label="Kod pocztowy" />
                    <TextField source="street" label="Ulica" />
                    <TextField source="city" label="Miasto" />
                    <TextField source="klorNumber" label="Klor" />
                    <TextField source="tzmoNumber" label="Numer TZMO" />
                    {PermissionHandler(
                        permissions,
                        'PickupPoints',
                        'ShowWorkers'
                    ) && (
                        <ReferenceManyField
                            label="Pracownicy"
                            target="pickupPoints"
                            reference="workers"
                        >
                            <CustomDataGrid>
                                <TextField source="name" label="Imię" />
                                <TextField source="surname" label="Nazwisko" />
                                <TextField source="email" label="E-mail" />
                                <TextField source="type" label="Typ" />
                                <CustomTypeField source="type" label="Typ" />
                                <BooleanField
                                    source="status"
                                    label="Status"
                                    valueLabelTrue="aktywny"
                                    valueLabelFalse="nieaktywny"
                                />
                            </CustomDataGrid>
                        </ReferenceManyField>
                    )}
                </SimpleShowLayout>
            </Show>
        </>
    )
}
